import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Blocks from "../components/blocks/";
import Layout from "../components/layout";
import Hero from "../components/hero";

export const query = graphql`
  query AboutPageQuery {
    page: allSanityPageAbout {
      nodes {
        title
        subtitle
        _rawBody(resolveReferences: { maxDepth: 8 })
      }
    }
  }
`;

const About = ({ data }) => {
  const page = data.page.nodes[0];

  return (
    <Layout>
      <SEO title={page.title} />
      <Hero title={page.title} description={page.subtitle}></Hero>
      <div className="container container--column">
        <Blocks data={page._rawBody} />
      </div>
    </Layout>
  );
};

export default About;
