import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../../util/serializers";

const Blocks = ({ data }) => {
  return (
    data && (
      <BlockContent
        className="block-body"
        blocks={data}
        serializers={serializers}
        renderContainerOnSingleChild={true}
      />
    )
  );
};

export default Blocks;
